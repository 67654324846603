export interface ISeoSitetag {
  customUrl: string;
  title: string | null;
  description: string | null;
  image: string | null;
}

// maintain this list with the the routes
const companyName = ' - DFS Finance';
const imagesPath = 'assets/images/';
const description =
  'We’re proud to offer the ag industry the custom financing options and competitive rates you need, and the personalized service you value.';
export const seoSitetags: ISeoSitetag[] = [
  {
    customUrl: '/',
    title: 'Home' + companyName,
    description: description,
    image: imagesPath + 'home-hero.jpg',
  },
  {
    customUrl: '/about',
    title: 'About' + companyName,
    description: description,
    image: imagesPath + 'about-hero.jpg',
  },
  {
    customUrl: '/adminPermissions',
    title: 'Dealer Admin Permissions ' + companyName,
    description: description,
    image: imagesPath + 'contact-hero.jpg',
  },
  {
    customUrl: '/application',
    title: 'Application' + companyName,
    description: description,
    image: imagesPath + 'about-hero.jpg',
  },
  {
    customUrl: '/applicationforms',
    title: 'Application Forms' + companyName,
    description: description,
    image: imagesPath + 'application-forms-hero.jpg',
  },
  {
    customUrl: '/becomeADealer',
    title: 'Become a dealer' + companyName,
    description: description,
    image: imagesPath + 'become-a-dealer-hero.jpg',
  },
  {
    customUrl: '/contact',
    title: 'Contact Us' + companyName,
    description: description,
    image: imagesPath + 'contact-hero.jpg',
  },
  {
    customUrl: '/contact?contacttype=1',
    title: 'Request Contract Number' + companyName,
    description: description,
    image: imagesPath + 'contact-hero.jpg',
  },
  {
    customUrl: '/contact?contacttype=2',
    title: 'Request ACH Packet' + companyName,
    description: description,
    image: imagesPath + 'contact-hero.jpg',
  },
  {
    customUrl: '/customerRegistration',
    title: 'Customer Registration ' + companyName,
    description: description,
    image: imagesPath + 'customer-registration-hero.jpg',
  },
  {
    customUrl: '/dealerContact',
    title: 'Contact Us ' + companyName,
    description: description,
    image: imagesPath + 'contact-hero.jpg',
  },
  {
    customUrl: '/dealerRegistration',
    title: 'Dealer Registration ' + companyName,
    description: description,
    image: imagesPath + 'dealer-registration-hero.jpg',
  },
  {
    customUrl: '/faqs',
    title: 'FAQs' + companyName,
    description: description,
    image: imagesPath + 'faqs-hero.jpg',
  },
  {
    customUrl: '/financing/Farm',
    title: 'Equipment Financing' + companyName,
    description: description,
    image: imagesPath + 'farm-equipment-hero.jpg',
  },
  {
    customUrl: '/financing/Irrigation',
    title: 'Irrigation Financing' + companyName,
    description: description,
    image: imagesPath + 'irrigation-equipment-hero.jpg',
  },
  {
    customUrl: '/home',
    title: 'Home' + companyName,
    description: description,
    image: imagesPath + 'home-hero.jpg',
  },
  {
    customUrl: '/login',
    title: 'Login' + companyName,
    description: description,
    image: imagesPath + 'login-bg.jpg',
  },
  {
    customUrl: '/paymentCalculator',
    title: 'Loan Payment Calculator ' + companyName,
    description: description,
    image: imagesPath + 'contact-hero.jpg',
  },
  {
    customUrl: '/requestPayoff',
    title: 'Request Payoff Quotes ' + companyName,
    description: description,
    image: imagesPath + 'about-hero.jpg',
  },
];

// https://stackoverflow.com/questions/48330535/dynamically-add-meta-description-based-on-route-in-angular
// Or configure the above info in with routes like this:
//  {
//   path: 'about',
//   component: AboutComponent,
//   data: {
//     title: 'About',
//     description:'Description Meta Tag Content',
//     ogUrl: 'your og url'
//   }
// },
//  And access it through the NgOnInit in app.component.ts like this:
// ngOnInit() {
//     this.router.events.pipe(
//        filter((event) => event instanceof NavigationEnd),
//        map(() => this.activatedRoute),
//        map((route) => {
//          while (route.firstChild) route = route.firstChild;
//          return route;
//        }),
//        filter((route) => route.outlet === 'primary'),
//        mergeMap((route) => route.data)
//       )
//       .subscribe((event) => {
//         this._seoService.updateTitle(event['title']);
//         this._seoService.updateOgUrl(event['ogUrl']);
//         //Updating Description tag dynamically with title
//         this._seoService.updateDescription(event['title'] + event['description'])
//       });
//     }
